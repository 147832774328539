.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: #000;
}

.parallax {
  height: 100vh;
  width: 100%;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(0.9px); /* Apply Gaussian blur */
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  flex: 1;
  background-color: rgba(0, 0, 0, 0); /* Slightly transparent black */
  color: #f9f9f9;
  font-family: 'Calibri', sans-serif;
  font-size: 10pt;
  text-align: justify;
}

.fullscreen-modal {
  position: fixed;
  top: 60px; /* Adjust to fit below the header */
  bottom: 40px; /* Adjust to fit above the footer */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.777);
  overflow-y: auto;
  z-index: 999;
}

.fullscreen-modal .close-button {
  position: absolute;
  top: 50px;
  right: 20px;
  background-color: #aa00ee;
  border: none;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.fullscreen-modal .popup-content {
  padding: 20px;
}

.fullscreen-modal h2 {
  padding-top:15px;
  text-align: left;
}
