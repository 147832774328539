.footer {
  font-family: 'Calibri', sans-serif;
  font-size: 10pt;
  font-weight: bold;
  color: #f9f9f9;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  width: 100%; /* Make the footer full width */
  margin: 0 auto;
  padding: 4px; /* Adjust padding to match the layout */
  text-align: center; /* Center text */
  border: 1px solid #000;
  border-top: 2px solid #090909; /* Add a top border with a lighter color for bevel effect */
  background-color: #090909;
  position: -webkit-sticky; /* Add this line */
  position: sticky;
  bottom: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.5); /* Add an outer shadow for bevel effect */
}

.footer img {
  height: 14pt; /* Adjust the height to match the font size */
  margin-right: 3.5px; /* Add some space between the logo and the text */
  padding-bottom: 3.5px;
  vertical-align: middle; /* Center the image vertically with the text */
  margin-bottom: 3px; /* Trim off the extra space at the bottom */
}

.footer-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  width: 100%; /* Ensure the content takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.footer-content p {
  margin: 4px 0; /* Add margin to separate paragraphs */
}

.footer-content a {
  margin: 0 4px;
  text-decoration: none;
  color: #aa00ee;
}

.footer-content a:hover {
  text-decoration: underline;
}
