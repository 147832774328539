/* Import the Cinzel font */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');

.header {
  position: -webkit-sticky; /* Add this line */
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical alignment */
  padding: 10px 20px;
  z-index: 1000;
  font-family: 'Raleway', sans-serif; /* Set font family to Calibri */
  font-size: 30px; /* Increase font size to 36px */
  font-weight: bold; /* Set font weight to bold */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.9); /* More apparent box-shadow */
  overflow: hidden; /* Prevent overflow */
}

.header .logo {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  max-width: 50%; /* Ensure the logo and brand combined do not exceed 50% */
  flex-shrink: 1; /* Allow shrinking */
}

.header .logo img {
  margin-top: 6px;
  height: 42px;
}

.header .logo .brand {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Add some space between logo and brand */
  flex-shrink: 1; /* Allow shrinking */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  white-space: nowrap; /* Prevent text from wrapping */
}

.brand .in, .brand .character {
  font-family: 'Cinzel', sans-serif; /* Apply Cinzel font to the span */
  font-weight: bold; /* Set font weight to bold */
}

.brand .in {
  color: #aa00ee;
}

.brand .character {
  color: rgb(220, 186, 108);
}

.brand .me {
  font-family: 'Raleway', sans-serif; /* Apply Calibri font to the span */
  color: #090909;
}

.brand .dot, .brand .tm {
  font-family: 'Calibri', sans-serif; /* Use Calibri for TM */
  font-size: 24px; /* Adjust size as needed */
  font-weight: 100; /* Make the TM text lighter */
  -webkit-text-stroke: 0; /* Remove text stroke for TM */
  color: #000; /* Set color for TM */
  position: relative; /* Position relative to adjust vertical alignment */
}

.brand .tm {
  top: -4px; /* Raise the TM text by 6px */
}

.header .nav {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  margin-left: auto;
  white-space: nowrap; /* Prevent text from wrapping */
}

.header a {
  text-decoration: none; /* Remove underline from header links */
}

.avatar {
  height: 40px; /* Adjust as needed */
  border-radius: 50%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .header {
    font-size: 24px; /* Adjust font size for smaller screens */
  }

  .header .logo {
    max-width: 50%; /* Ensure the logo and brand combined do not exceed 50% */
    flex-shrink: 1; /* Allow shrinking */
  }

  .header .logo .brand {
    flex-shrink: 1; /* Allow shrinking */
    overflow: visible; /* Prevent overflow */
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .brand .tm {
    font-size: 18px; /* Adjust size for TM on smaller screens */
  }
}