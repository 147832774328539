/* Global styles for the entire application */
body {
    margin: 0;
    font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #f9f9f9;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  /* Remove underline from all links */
  a {
      text-decoration: none; /* Ensure no underline by default */
    }
    
    a:hover {
      text-decoration: none !important; /* Ensure no underline on hover globally */
    }
  
  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f9f9f9;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #090909;
    border-radius: 5px;
    border: 2px solid #f9f9f9;
    box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.3);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

/* General styles */
.App {
    text-align: center;
  }
  /* Logo styles */
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  /* Header styles */
  .App-header {
    background-color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff;
  }
  /* Link styles */
  .App-link {
    color: #ff0;
  }